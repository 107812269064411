import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Nav from "../components/Nav";
import { useNavStore } from "../state/useStore";

const Layout = ({ children }) => {
	const setVisible = useNavStore((state) => state.setVisible);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setVisible(false);
			} else {
				setVisible(true);
			}
		};
		window.addEventListener("scroll", handleScroll);
		handleScroll();
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	switch (children.props.pageContext.type) {
		case "homepage":
			return <div className={"selection:bg-sage font-serif"}>{children}</div>;

		case "information":
			return (
				<div className={"selection:bg-sage font-serif"}>
					<AnimatePresence initial={true} mode={"popLayout"}>
						<Header key={"header-component"} />
						{children}
						<Footer key={"footer-component"} />
					</AnimatePresence>
				</div>
			);

		default:
			return (
				<div className={"selection:bg-sage font-serif"}>
					<AnimatePresence initial={true} mode={"popLayout"}>
						<Header key={"header-component"} />
						<Nav key={"nav-component"} />
						{children}
						<Footer key={"footer-component"} />
					</AnimatePresence>
				</div>
			);
	}
};

export default Layout;