import React from "react";
import { PortableText as BlockContent } from "@portabletext/react";
import { Link } from "gatsby";

const components = {
	block: {
		large: ({ children }) => <h3 className={"text-2xl"}>{children}</h3>,
		normal: ({ children }) => <p className={"text-base min-h-[1rem]"}>{children}</p>,
	},
	marks: {
		blockLink: ({ value, children }) => {
			switch (value.type) {
				case "internal":
					return (
						<LinkStyle>
							<Link
								to={`/${value?.reference?.prefixedSlug?.current || "/"}`}
								state={{ direction: "fromHome" }}
							>
								{children}
							</Link>
						</LinkStyle>
					);
				case "external":
					return (
						<LinkStyle>
							<a href={value?.link} target="_blank" rel="noopener noreferrer">
								{children}
							</a>
						</LinkStyle>
					);
				case "telephone":
					return (
						<LinkStyle>
							<a href={`tel:${value?.link}`}>{children}</a>
						</LinkStyle>
					);
				case "email":
					return (
						<LinkStyle>
							<a href={`mailto:${value?.link}`}>{children}</a>
						</LinkStyle>
					);
				default:
					return null;
			}
		},
	},
};

const PortableText = ({ content }) => {
	return <BlockContent value={content} components={components} />;
};

const LinkStyle = ({ children }) => {
	return <span className={"hover:text-terracotta transition-colors"}>{children}</span>;
};

export default PortableText;
