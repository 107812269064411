import clsx from "clsx";
import React from "react";

const Arrow = ({ flipped }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="34.98"
			height="114.22"
			viewBox="0 0 34.98 114.22"
			className={clsx("h-16 w-full", "sm:h-18 md:h-24 lg:h-36",{ "rotate-180": flipped })}
		>
			<path d="M34.96,57.05c-.16-.14-.34-.36-.57-.49-1.9-1.07-3.84-2.09-5.72-3.2-5.37-3.18-9.63-7.52-13.27-12.58-3.01-4.18-5.57-8.59-7.63-13.3-.87-1.99-2.04-3.86-2.73-5.9-.8-2.36-1.2-4.86-1.81-7.29-.49-1.94-1.14-3.84-1.52-5.79-.41-2.11-.56-4.26-.87-6.39-.1-.71-.34-1.41-.52-2.11C.06,.19,0,.34,0,.48,.09,1.59,.19,2.69,.29,3.8c.31,3.61,.53,7.23,.97,10.82,.3,2.44,.8,4.88,1.42,7.26,.92,3.5,1.73,7.08,3.16,10.38,1.47,3.38,3.47,6.56,5.51,9.65,4.25,6.45,9.4,11.98,16.69,15.11,.07,.03,.14,.06,.22,.09-.07,.03-.14,.06-.22,.09-7.29,3.13-12.43,8.67-16.69,15.11-2.04,3.09-4.04,6.27-5.51,9.65-1.43,3.3-2.24,6.88-3.16,10.38-.63,2.38-1.12,4.82-1.42,7.26-.44,3.59-.66,7.21-.97,10.82-.1,1.11-.2,2.21-.29,3.32,0,.14,.06,.29,.34,.48,.18-.7,.41-1.4,.52-2.11,.31-2.13,.46-4.28,.87-6.39,.38-1.95,1.03-3.86,1.52-5.79,.61-2.43,1.02-4.92,1.81-7.29,.69-2.04,1.86-3.92,2.73-5.9,2.06-4.71,4.62-9.12,7.63-13.3,3.63-5.05,7.89-9.39,13.27-12.58,1.88-1.11,3.82-2.13,5.72-3.2,.23-.13,.41-.35,.57-.49-.04-.02-.09-.05-.14-.07,.04-.02,.09-.05,.14-.07l-.02,.02Z" />
		</svg>
	);
};

export default Arrow;
