import create from "zustand";

export const useNavStore = create((set) => ({
	previous: null,
	next: null,
	exit: 0,
	visible: true,
	setPrevious: (status) => set((state) => ({ previous: status })),
	setNext: (status) => set((state) => ({ next: status })),
	setExit: (status) => set((state) => ({ exit: status })),
	setVisible: (status) => set((state) => ({ visible: status })),
}));