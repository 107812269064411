import clsx from "clsx";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import React, { forwardRef } from "react";
import Arrow from "../assets/icons/Arrow";
import { useNavStore } from "../state/useStore";

const Nav = forwardRef(() => {
	const previous = useNavStore((state) => state.previous);
	const next = useNavStore((state) => state.next);
	const setExit = useNavStore((state) => state.setExit);
	const visible = useNavStore((state) => state.visible);

	const variants = {
		visible: { opacity: 1, display: "block" },
		hidden: { opacity: 0, transitionEnd: { display: "none" } },
	};
	return (
		<motion.nav
			animate={visible ? "visible" : "hidden"}
			initial={"visible"}
			variants={variants}
			className={clsx(
				"fixed h-screen w-screen pointer-events-none text-center z-10 text-2xl top-0 left-0",
				"md:text-3xl"
			)}
		>
			<div
				className={clsx(
					"relative w-full flex justify-between mt-14 ",
					"sm:mt-20 md:mt-24 lg:h-full lg:mt-0 "
				)}
			>
				<button
					onClick={() => {
						setExit(100);
						navigate(`/${previous}`, { state: { direction: "left" } });
					}}
					className={clsx(
						"flex items-center justify-center pointer-events-auto w-14 p-2",
						"md:w-24"
					)}
				>
					<Arrow flipped />
				</button>
				<div className={clsx("w-full aspect-[3/2] min-w-0 min-h-0 ", "lg:hidden")}></div>
				<button
					onClick={() => {
						setExit(-100);
						navigate(`/${next}`, { state: { direction: "right" } });
					}}
					className={clsx(
						"flex items-center justify-center pointer-events-auto w-14 p-2",
						"md:w-24"
					)}
				>
					<Arrow />
				</button>
			</div>
		</motion.nav>
	);
});

export default Nav;
