import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import React, { forwardRef } from "react";
import Lettermark from "../assets/icons/Lettermark";
import PortableText from "./PortableText";

const Footer = forwardRef(() => {
	const {
		global: { footer },
	} = useStaticQuery(getData);
	return (
		<div
			className={clsx(
				"w-screen grid grid-cols-1 pl-4 pr-4 py-8 gap-8 mt-8 overflow-hidden left-0 text-center",
				"sm:grid-cols-4 sm:text-left",
				"md:gap-3 md:pl-0 md:pr-12 md:py-12 md:mt-28"
			)}
		>
			<div className={clsx("pt-2 flex justify-center")}>
				<div className={clsx("w-8 flex", "sm:w-12", "md:w-20")}>
					<Lettermark />
				</div>
			</div>
			{footer.map((footer) => {
				return (
					<div className={"flex flex-col gap-2"} key={footer._key}>
						<PortableText content={footer._rawContent} />
					</div>
				);
			})}
		</div>
	);
});

export default Footer;

const getData = graphql`
	{
		global: sanityGlobal {
			footer {
				_key
				_rawContent(resolveReferences: { maxDepth: 10 })
			}
		}
	}
`;
