import clsx from "clsx";
import { Link } from "gatsby";
import React, {forwardRef} from "react";
import Logo from "../assets/icons/Logo";

const Header = forwardRef(() => {
	return (
		<header
			className={clsx(
				"w-full h-14 flex justify-center items-center",
				"sm:h-20 md:h-24 lg:h-28"
			)}
		>
			<div className={clsx("w-full h-full z-10 px-14", "sm:w-4/5 md:w-2/3 lg:w-1/2")}>
				<Link
					to="/"
					className={clsx(
						"w-full h-full flex justify-center translate-y-8",
						"sm:translate-y-10 md:translate-y-12 lg:translate-y-14"
					)}
					aria-label="Home"
				>
					<Logo />
				</Link>
			</div>
		</header>
	);
});

export default Header;
